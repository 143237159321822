import { AxiosResponse } from "axios";
import ApiService from "./ApiService";

const API_URL = "room-groups";

class RoomGroupService {

    public static getRoomGroupById(id): Promise<AxiosResponse> {
        return ApiService.get(API_URL + "/" + id);
    }

    public static createRoomGroup(formData: FormData): Promise<AxiosResponse> {
        return ApiService.post(API_URL, formData);
    }

    public static updateRoomGroup(formData: FormData): Promise<AxiosResponse> {
        return ApiService.post(API_URL + "/edit", formData);
    }

    public static deleteRoomGroup(id): Promise<AxiosResponse> {
        return ApiService.delete(API_URL + "/" + id);
    }
}

export default RoomGroupService;