
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { IUser } from "@/core/data/user";
import UserService from "@/core/services/UserService";
import Swal from "sweetalert2";
import UserRoleService from "@/core/services/UserRoleService";
export default defineComponent({
  components: {
    Datatable,
  },
  setup(props) {
    const search = ref<string>("");
    const tableHeader = ref([
      {
        name: "",
        key: "profilePic",
      },
      {
        name: "Name",
        key: "fullName",
        sortable: false,
      },
      {
        name: "Email",
        key: "email",
        sortable: false,
      },
      {
        name: "",
        key: "actions",
      },
    ]);
    var currentPage = ref(1);
    var rowsPerPage = ref(10);
    var totalData = ref(0);
    var tableData = ref<Array<IUser>>([]);

    const initUserData = () => {
      var searchUserFilter = {
        page: currentPage.value,
        text_search: search.value,
        role: "ROLE_USER",
      };
      UserService.getAllUsers(searchUserFilter).then((response) => {
        totalData.value = response.data.total;
        tableData.value.splice(
          0,
          tableData.value.length,
          ...response.data.data
        );
      });
    };

    const deleteUserRole = (user) => {
      Swal.fire({
        title: `Would you like to delete this user from role teacher?`,
        text: user.name,
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#F64E60",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          var assignRoleRequest = {
            id: user.id,
            roleName: "ROLE_USER",
          };
          UserRoleService.deleteUserRole(assignRoleRequest)
            .then((response) => {
              Swal.fire({
                text: "Delete successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              initUserData();
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Delete failed",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Confirm",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };

    const searchItems = () => {
      initUserData();
    };

    const onPageChange = (pageNumber) => {
      currentPage.value = pageNumber;
      initUserData();
    };

    onMounted(() => {
      initUserData();
    });

    return {
      tableHeader,
      tableData,
      initUserData,
      searchItems,
      search,
      currentPage,
      totalData,
      rowsPerPage,
      onPageChange,
      deleteUserRole,
    };
  },
});
