
import RoomService from "@/core/services/RoomService";
import RoomMemberCard from "@/views/studio-lab/components/cards/RoomMemberCard.vue";
import Swal from "sweetalert2";
import { defineComponent, onMounted, ref } from "vue";
import { IRoom } from "@/core/data/room";
// import { SearchUserFilter } from "@/core/filterData/searchUserFilter";
import { SearchRoomFilter } from "@/core/filterData/searchRoomFilter";
import Userlist from "@/views/studio-lab/role/UserList.vue";
import Teacherlist from "@/views/studio-lab/role/TeacherList.vue";

export default defineComponent({
  components: { RoomMemberCard, Userlist, Teacherlist },
  setup() {
    const rooms = ref<Array<IRoom>>([]);
    const search = ref<string>("");
    // var tableData = ref<Array<IUser>>([]);
    var totalData = ref(0);
    var currentPage = ref(1);
    var rowsPerPage = ref(10);
    var totalRoom = ref(0);
    var currentRoomPage = ref(1);

    const initRooms = () => {
      var searchRoomFilter: SearchRoomFilter = {
        page: currentRoomPage.value,
        size: 6,
      };
      RoomService.getAllRooms(searchRoomFilter).then((response) => {
        rooms.value = response.data.data;
        totalRoom.value = response.data.total;
      });
    };

    onMounted(() => {
      initRooms();
    });

    return {
      rooms,
      initRooms,
      search,
      totalData,
      currentPage,
      rowsPerPage,
      totalRoom,
      currentRoomPage,
    };
  },
});
