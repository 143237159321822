import { AxiosResponse } from "axios";
import ApiService from "./ApiService";

const API_URL = "rooms";

class RoomService {
  public static getAllRooms(searchRoomFilter): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/filter", searchRoomFilter);
  }

  public static getRoomById(id): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/" + id);
  }

  public static createRoom(formData: FormData): Promise<AxiosResponse> {
    return ApiService.post(API_URL, formData);
  }

  public static updateRoom(formData: FormData): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/edit", formData);
  }

  public static deleteRoom(id): Promise<AxiosResponse> {
    return ApiService.delete(API_URL + "/" + id);
  }
}

export default RoomService;
