
import { defineComponent, onMounted, ref, PropType } from "vue";
import { Modal } from "bootstrap";
import { IRoomUser } from "@/core/data/roomUser";

export default defineComponent({
  name: "view-user-modal",
  props: {
    users: Object as PropType<Array<IRoomUser>>,
  },
  setup() {
    let thisModalObj;
    const viewUserModalRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      thisModalObj = new Modal(viewUserModalRef.value);
    });

    const showModal = () => {
      thisModalObj.show();
    };

    return {
      viewUserModalRef,
      showModal,
    };
  },
});
