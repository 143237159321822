
import { defineComponent, onMounted, ref, PropType } from "vue";
import { Modal } from "bootstrap";
import { IRoomGroup } from "@/core/data/roomGroups";

export default defineComponent({
    name: "view-group-member-modal",
    props: {
        group: Object as PropType<IRoomGroup>,
    },
    setup() {
        let thisModalObj;
        const viewGroupMemberModalRef = ref<null | HTMLElement>(null);

        onMounted(() => {
            thisModalObj = new Modal(viewGroupMemberModalRef.value);
        });

        const showModal = () => {
            thisModalObj.show();
        };

        return {
            viewGroupMemberModalRef,
            showModal,
        };
    },
});
