
import { PropType, defineComponent, ref } from "vue";
import { IRoom } from "@/core/data/room";
import { IRoomGroup } from "@/core/data/roomGroups";
import ViewUserModal from "@/views/studio-lab/components/modals/ViewUserListModal.vue";
import ViewGroupMemberModal from "@/views/studio-lab/components/modals/ViewGroupMemberModal.vue";
import moment from "moment";
import RoomService from "@/core/services/RoomService";
import Swal from "sweetalert2";
import RoomGroupService from "@/core/services/RoomGroupService";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "room-member-card",
  components: { ViewUserModal, ViewGroupMemberModal },
  props: {
    room: Object as PropType<IRoom>,
  },
  emit: ["onRefreshRoom"],
  setup(props, { emit }) {
    // const router = useRouter();
    const viewUserModalRef = ref();
    const viewGroupMemberRef = ref();
    const roomGroup = ref<IRoomGroup>();

    const showUsers = () => {
      viewUserModalRef.value.showModal();
    };

    const showGroupMembers = (group: IRoomGroup) => {
      roomGroup.value = group;
      viewGroupMemberRef.value.showModal();
    };

    const formatDate = (value) => {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      } else return "-";
    };

    const deleteRoom = () => {
      Swal.fire({
        title: `Would you like to delete this room (${props.room?.name})?`,
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#F64E60",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          RoomService.deleteRoom(props.room?.id)
            .then((response) => {
              Swal.fire({
                text: "Delete successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              emit("onRefreshRoom");
            })
            .catch(({response}) => {
              Swal.fire({
                title: "Delete failed",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Confirm",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };

    const deleteGroup = (roomGroup) => {
      Swal.fire({
        title: `Would you like to delete this group (${roomGroup.name})?`,
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#F64E60",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          RoomGroupService.deleteRoomGroup(roomGroup.id)
            .then((response) => {
              Swal.fire({
                text: "Delete successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              emit("onRefreshRoom");
            })
            .catch(({ response }) => {
              Swal.fire({
                title: "Delete failed",
                text: response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Confirm",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };

    // onMounted(() => {
    //   initGroupWork();
    // });
    return {
      viewUserModalRef,
      viewGroupMemberRef,
      roomGroup,
      showGroupMembers,
      showUsers,
      formatDate,
      deleteRoom,
      deleteGroup,
    };
  },
});
