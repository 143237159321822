import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_view_user",
  ref: "viewUserModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content rounded" }
const _hoisted_4 = { class: "modal-body scroll-y" }
const _hoisted_5 = { class: "flex-shrink-0 symbol symbol-circle symbol-50px me-3" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "d-flex flex-column" }
const _hoisted_8 = { class: "fw-bold m-0" }
const _hoisted_9 = { class: "text-muted m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (usr, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "d-flex align-items-center mb-3"
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: usr.user.avatar,
                  alt: usr.user.name
                }, null, 8, _hoisted_6)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h4", _hoisted_8, _toDisplayString(usr.user.name), 1),
                _createElementVNode("h5", _hoisted_9, _toDisplayString(usr.user.email), 1)
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ], 512))
}